$baseColor: #1B9E00;

.select {
    background-color: $baseColor;
    color: #fff;
}

.zoom-control--root {
    position: absolute;
    top: unset;
    left: unset;
    bottom: 90px;
    right: 25px;

    @media only screen and (max-width: 767px) {
        bottom: 68px;
    }
}

.zoom-control--root button {
    background-color: $baseColor;
    border-radius: 5px;
}

.zoom-control--root button:hover {
    cursor: pointer;
}

.ol-scale-line {
    background: none;
    padding: 5px;
    position: absolute;
    right: 20px;
    left: inherit;
}
.ol-scale-line-inner {
    color: black;
    border-color: black;
}

.layer-switch--button-container {
    padding: 0;
    left: 30%;
    top: 43px;
    margin-left: 9px;
    background-color: initial;
}

.layer-switch--button {
    background-color: $baseColor;
    border-radius: 5px;

}

.layer-switch--container {
    background-color: $baseColor;
    left: 30% !important;
    margin-left: 45px;
    top: 43px;
}